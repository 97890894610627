import { Typography } from 'antd';
import { Outlet } from 'react-router-dom';
import './project-general-view.scss';
import { ProjectTabs } from '@/features';

const { Title } = Typography;

export const ProjectGeneralView = () => {
  return (
    <div className="project-general-view-container">
      <Title level={3} style={{ fontWeight: 500, color: '#333333', marginBottom: '32px' }}>
        Загородный дом
      </Title>
      <ProjectTabs />
      <Outlet />
    </div>
  );
};
