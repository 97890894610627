import { useState } from 'react';
import './project-related.scss';
import { RelatedEnum } from '../../types';
import { ProjectRelatedContracts } from '../project-related-contracts/project-related-contracts';
import { ProjectRelatedJournals } from '../project-related-journals/project-related-journals';
import { ProjectRelatedTabs } from '../project-related-tabs/project-related-tabs';

export const ProjectRelated = () => {
  const [activeRelated, setActiveRelated] = useState(RelatedEnum.contracts);
  return (
    <>
      <ProjectRelatedTabs activeRelated={activeRelated} setActiveRelated={setActiveRelated} />
      {activeRelated === RelatedEnum.contracts ? <ProjectRelatedContracts /> : <ProjectRelatedJournals />}
    </>
  );
};
