import { Button } from 'antd';
import TextArea from 'antd/es/input/TextArea';

export const ObjectFormBuilder = () => {
  return (
    <form>
      <div>
        <div className="form-label-input primary" style={{ marginTop: '16px' }}>
          Адрес
        </div>
        <TextArea />
      </div>
      <div className="dottedLine" />
      <div className="object-modal-address-button">
        <Button type="primary">Добавить</Button>
      </div>
    </form>
  );
};
