import { useState } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { Typography, Table, Select, Button, Card, Row, Col } from 'antd';

import './project-members.scss';

const { Title } = Typography;
const { Option } = Select;

type ProjectType = {
  key: string;
  organization: string;
  role: string;
  object: string;
  name: string;
  INN: string;
  KPP: string;
  OGRN: string;
  address: string;
};

const dataSource: ProjectType[] = [
  {
    key: '1',
    organization: 'ООО "Интэнт"',
    role: 'Подрядчик',
    object: 'Объект 1',
    name: "ООО 'ИНТЕНТ'",
    INN: '7839136848',
    KPP: '783901001',
    OGRN: '1217800024265',
    address: 'Юр. адрес: 190020, г. Санкт-Петербург, наб. Обводного канала, д. 138, оф. 432',
  },
  {
    key: '2',
    organization: 'ООО "Интэст"',
    role: 'Заказчик',
    object: 'Объект 2',
    name: "ООО 'ИНТЕНТ'",
    INN: '7839136848',
    KPP: '783901001',
    OGRN: '1217800024265',
    address: 'Юр. адрес: 190020, г. Санкт-Петербург, наб. Обводного канала, д. 138, оф. 432',
  },
  {
    key: '3',
    organization: 'ООО "Интэст"',
    role: 'Заказчик',
    object: 'Объект 3',
    name: "ООО 'ИНТЕНТ'",
    INN: '7839136848',
    KPP: '783901001',
    OGRN: '1217800024265',
    address: 'Юр. адрес: 190020, г. Санкт-Петербург, наб. Обводного канала, д. 138, оф. 432',
  },
];

const columns = [
  {
    title: 'Организация',
    dataIndex: 'organization',
    key: 'organization',
  },
  {
    title: 'Роль',
    dataIndex: 'role',
    key: 'role',
  },
  {
    title: 'Объект',
    dataIndex: 'object',
    key: 'object',
  },
];

export const ProjectMembers = () => {
  const [selectedProject, setSelectedProject] = useState<ProjectType | null>(null);
  const [selectedProjectKey, setSelectedProjectKey] = useState<string | null>(null);

  const handleSelecteProjectKey = (item: string) => {
    setSelectedProjectKey(item);
    const selectedProjectPrev = dataSource.find((project) => project?.key === item);
    setSelectedProject(selectedProjectPrev || null);
  };

  const handleClearSelected = () => {
    setSelectedProject(null);
    setSelectedProjectKey(null);
  };

  return (
    <>
      <div className="project-members-main-wrapper">
        <div className="project-members-right-tab-container table-empry">
          <Card style={{ borderRadius: '8px', backgroundColor: 'white' }}>
            <Title level={4} style={{ marginBottom: '20px' }}>
              Участвующие в проекте организации
            </Title>
            <Table
              dataSource={[]}
              columns={columns}
              pagination={false}
              bordered
              style={{ backgroundColor: '#fff', borderRadius: '8px' }}
            />
          </Card>
        </div>

        <div className="project-members-left-tab-container">
          <Card style={{ borderRadius: '8px', backgroundColor: 'white' }}>
            <Title level={4} style={{ marginBottom: '24px' }}>
              Добавление организации
            </Title>

            <div style={{ marginBottom: '24px' }}>
              <div style={{ color: '#333333', marginBottom: '4px' }}>Оргиназиция</div>
              <Select
                showSearch
                placeholder="Введите название или ИНН"
                style={{ width: '100%' }}
                value={selectedProjectKey}
                onChange={handleSelecteProjectKey}
              >
                {dataSource.map((item) => {
                  return <Option value={item.key}>{item.name}</Option>;
                })}
              </Select>
            </div>
            {selectedProject ? (
              <div className="project-selected-container">
                <div className="project-selected-header">
                  <div className="project-selected-organization-name">
                    {selectedProject.organization}
                  </div>
                  <CloseOutlined
                    onClick={handleClearSelected}
                    className="project-selected-close-icon"
                  />
                </div>
                <Row className="project-selected-detail">
                  <Col span={6} className="project-selected-label">ИНН</Col>
                  <Col span={18}>{selectedProject.INN}</Col>
                </Row>
                <Row className="project-selected-detail">
                  <Col span={6} className="project-selected-label">КПП</Col>
                  <Col span={18}>{selectedProject.KPP}</Col>
                </Row>
                <Row className="project-selected-detail">
                  <Col span={6} className="project-selected-label">ОГРН</Col>
                  <Col span={18}>{selectedProject.OGRN}</Col>
                </Row>
                <Row className="project-selected-detail">
                  <Col span={6} className="project-selected-label">Юр. адрес</Col>
                  <Col span={18}>{selectedProject.address}</Col>
                </Row>
              </div>
            ) : null}
            <Button type="primary" block onClick={handleClearSelected}>
              Добавить организацию
            </Button>
          </Card>
        </div>
      </div>
    </>
  );
};
