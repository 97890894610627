import { Select } from 'antd';

interface IObjectFormSelector {
  //   name?: string;
  // control?: Control<any>;
  label?: string;
  placeholder?: string;
  width?: string | number;
  // rules?: RegisterOptions;
  errorMessage?: string;
  disabled?: boolean;
}

export const ObjectFormSelector = ({ label, placeholder, width, errorMessage, disabled }: IObjectFormSelector) => {
  return (
    <div className="form-section-label">
      <div className="form-label-input">{label}</div>
      <Select
        placeholder={placeholder}
        style={{ width: `${width}%` }}
        className="object-form-input"
        disabled={disabled}
      />
      {errorMessage ? <div className="object-form-error">{errorMessage}</div> : null}
    </div>
  );
};
