import { createElement } from 'react';
import { createBrowserRouter, Navigate, RouterProvider, useRouteError } from 'react-router-dom';
import {
  ProjectDetailRoute,
  ProjectMembersRoute,
  ProjectObjectRoute,
  ProjectRelatedRoute,
  ProjectSettingsRoute,
} from '@pages/general';
import { createAdminLayout, createGuestLayout, createUserLayout } from '@pages/layouts';
import { NotFoundPageRoute } from '@pages/page-not-found';
import { ProjectsPageRoute } from '@pages/projects';
import { createProjectGeneralView } from '@pages/views/project-general';
import { createProjectWrapper } from '@pages/wrappers/project';
import { routes } from '@shared/lib/react-router';
import { createProjectObjectsView } from '@pages/views/project-objects';

// https://github.com/remix-run/react-router/discussions/10166
function BubbleError() {
  const error = useRouteError();
  if (error) throw error;
  return null;
}

const router = createBrowserRouter([
  {
    errorElement: createElement(BubbleError),
    children: [
      createUserLayout(
        ProjectsPageRoute(),
        createProjectWrapper(
          createProjectGeneralView(
            ProjectSettingsRoute(),
            ProjectMembersRoute(),
            ProjectDetailRoute(),
            ProjectObjectRoute(),
            ProjectRelatedRoute(),
          ),
          createProjectObjectsView(),
          // createProjectAnalyticsView()
          // createProjectContractsView()
          // etc...
        ),
        // TODO:написать navigate конфиг, чтобы нижнее выражение привести к исполняемой функции
        { path: '/', element: <Navigate to={routes.projects()} /> },
      ),
      createGuestLayout(NotFoundPageRoute()),
      createAdminLayout(),
      { path: '*', element: <Navigate to={routes.page_not_found()} /> },
    ],
  },
]);

export function BrowserRouter() {
  return <RouterProvider router={router} />;
}
