import { useEffect } from 'react';
import { Splitter } from 'antd';
import { Outlet } from 'react-router-dom';
import { Notifications, useModalStore } from '@features/notifications';
import { test } from '@shared/api/rest/';
import { Footer } from './footer';
import { UserHeader } from './header/header';

export const UserLayout = () => {
  const { isOpen } = useModalStore();
  useEffect(() => {
    test.then((resp) => console.log(resp));
  }, []);
  return (
    // TODO:   из-за сплиттеров до правильного рендеринга (0-2  frame) страницы происходит флешскрин.
    // когда-нибудь нужно будет с этим разобраться
    //   v2: нужно разделить интерфейс, для мобильных устройств - использовать не сплиттеры, высчитывать брейкпоинты: useBreakpoint из antd
    <Splitter style={{ minHeight: 'calc(100vh - 104px)' }}>
      <Splitter.Panel style={{ padding: '0px 0px' }} defaultSize="85%" min="70%" max="100%">
        <UserHeader />
        <main style={{ minHeight: 'calc(100vh - 104px)', display: 'flex', position: 'relative' }}>
          <Outlet />
        </main>
        <Footer />
      </Splitter.Panel>
      {isOpen && (
        <Splitter.Panel min="15%" max="30%" resizable={isOpen}>
          <Notifications />
        </Splitter.Panel>
      )}
    </Splitter>
  );
};
