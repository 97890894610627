import React, { useState } from 'react';
import { Modal, Typography, Radio, RadioChangeEvent } from 'antd';
import './object-modal-add-address.scss';
import { ObjectFormBuilder, ObjectFormMail } from './ui';

const { Title } = Typography;

interface IObjectModalAddress {
  isModalAddress: boolean;
  setIsModalAddres: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ObjectModalAddress = ({ isModalAddress, setIsModalAddres }: IObjectModalAddress) => {
  const [selectedType, setSelectedType] = useState(1);

  const handleModalClose = () => {
    setIsModalAddres(false);
  };

  const handleSelectType = (item: RadioChangeEvent) => {
    setSelectedType(item.target.value);
  };

  return (
    <Modal
      title={null}
      open={isModalAddress}
      onOk={handleModalClose}
      onCancel={handleModalClose}
      footer={null}
      centered
      width={400}
    >
      <div>
        <Title level={4}>Добавление адреса</Title>
        <div>
          <div className="form-label-input primary" style={{ marginBottom: '8px' }}>
            Тип адреса
          </div>
          <Radio.Group value={selectedType} onChange={handleSelectType}>
            <Radio value={1}>Строительный</Radio>
            <Radio value={2}>Почтовый</Radio>
          </Radio.Group>
        </div>
        {selectedType === 1 ? <ObjectFormBuilder /> : <ObjectFormMail />}
      </div>
    </Modal>
  );
};
