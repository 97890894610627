import { useState } from 'react';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { Typography, Card, Modal, Button } from 'antd';
import './project-object.scss';
import { ObjectFormCreate } from '@/features';

const { Title, Text } = Typography;
export const ProjectObject = () => {
  const [isForm, setIsForm] = useState(false);
  const handleIsForm = (status: boolean) => {
    setIsForm(status);
  };
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      {isForm ? <ObjectFormCreate handleIsForm={handleIsForm} /> : null}
      <>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Title level={3} className="project-members-title">
            Список обьектов строительства
          </Title>
          <Button>Добавить обьект</Button>
        </div>
        <div className="project-object-cards-container">
          <Card className="custom-card" bordered={false}>
            <div className="card-content">
              <div className="card-info">
                <Title level={5} className="card-title">
                  Жилой дом
                </Title>
                <Text className="card-address">
                  Ленинградская область, Кировский район, дер. Горная Шальдиха, ул. Хвойная ул. 40
                </Text>
              </div>
              <div className="card-actions">
                <div className="icon-container">
                  <EditOutlined className="icon-custom edit-icon" onClick={() => handleIsForm(true)} />
                  <DeleteOutlined className="icon-custom delete-icon" onClick={showModal} />
                </div>

                <Text className="card-tag">Строительство</Text>
              </div>
            </div>
          </Card>
        </div>
      </>
      <Modal title={null} open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={null} centered width={400}>
        <div>
          <Title level={4}>Удалить?</Title>
          <Text>Вы действительно хотите удалить?</Text>
          <div
            style={{
              height: '1px',
              backgroundColor: '#e0e0e0',
              margin: '24px 0',
            }}
          />
          <div
            style={{
              marginTop: '24px',
              display: 'flex',
              gap: '8px',
            }}
          >
            <Button type="primary" onClick={handleOk}>
              Да
            </Button>
            <Button onClick={handleCancel}>Нет</Button>
          </div>
        </div>
      </Modal>
    </>
  );
};
