import { Typography } from 'antd';
import { Link } from 'react-router-dom';

export const Footer = () => {
  return (
    <footer
      style={{
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        padding: '12px 32px 12px 32px',
        borderTop: '1px solid rgba(0, 0, 0, 0.12)',
      }}
    >
      <Typography>ООО «Интэнт». Санкт-Петербург</Typography>
      <Link to={'#'}>Обратная связь</Link>
    </footer>
  );
};
