import { Layout } from "antd";
import { useState } from "react";

const { Sider } = Layout;
import { NavigationProject } from "./navigation-project";
import { NavigationMenu } from "./navigation-menu";

import "./navigation.scss";
import { NavigationContracts } from "@pages/wrappers/project/ui/navigation-contracts";

const Navigation = () => {
  const [collapsed, setCollapsed] = useState(false);
  return (
    <Sider
      width={240}
      theme={"light"}
      collapsible
      collapsed={collapsed}
      onCollapse={(value) => setCollapsed(value)}
    >
      <NavigationProject
        projectId="1"
        collapsed={collapsed}
        projectName="Загородный дом"
        projectDescription="Ленинградская область, Кировский район, дер. Горная Шальдиха, ул. Хвойная уч. 40"
      />
      <NavigationMenu projectId="1" collapsed={collapsed} />
      <NavigationContracts />
    </Sider>
  );
};

export default Navigation;
