import * as axios from 'axios';
import { env } from '@shared/config.ts';

export const RestAPI = axios.default.create({
  baseURL: env.API_BASE_URL,
});
RestAPI.interceptors.request.use((config) => {
  config.headers.Authorization =
    'Bearer eyJhbGciOiJSUzI1NiJ9.eyJ1c2VySWQiOiJiNWE2MDdjZC0zMjNiLTExZWItYmQzZC1kMDBkMTI2NDY0ZjAiLCJlbXBsb3ltZW50SWQiOiJiMDhiOTUxOC1jMzhkLTExZWItOTRkMi1kMDBkMTRkYjZmOTEiLCJpbm4iOiI3ODEwNDk1MTg1LTc4MDEwMTAwMSIsInJvbGVOYW1lIjoiYWNjb3VudDp3b3JrZXIiLCJyb2xlSWQiOiJXT1JLRVIiLCJlbWFpbCI6IjlAbWFpbC5ydSIsInBob25lIjoiKzc5MDgyNTUzMjA0IiwiaWF0IjoxNzI5Njc5NjI2fQ.Pa_4LA6q0u3SHtoNm30eFD3GGgu-Gmq4Vh8IuIe8TKXNDyc2HxxEWEPFC6-QUghfC4D2WM0v148kE53zHQrrvsMQHrIHFddWOUdsak8-5hfQ3wcn62__JAgJlApt3W6HlAivG0Kp2o8OWe_FYiftwb3HmAMjTbDRuPwez8yupYCdBBzVZFjtAGADVBY2PhFBYMMew6cklvDO5IzRRE4ZROjYU9rN9Usx0F402U3PQ_bCViWSem1mvlreRbXLEOWieT9sL66-_BZYgAHUX7g6FX-4B-342PSJ5Z1GmAxSNSWyhiTgOI97xxv1eNPeIqyNtvqZsdZDEMyf79TXr3Oe8g';
  return config;
});
