import { RelatedEnum } from '../../types';

import './project-related-tabs.scss';

interface IProjectRelatedTabs {
  activeRelated: RelatedEnum;
  setActiveRelated: React.Dispatch<React.SetStateAction<RelatedEnum>>;
}

export const ProjectRelatedTabs = ({ activeRelated, setActiveRelated }: IProjectRelatedTabs) => {
  const handleSelectRelated = (related: RelatedEnum) => {
    setActiveRelated(related);
  };
  return (
    <div className="related-tabs-wrapper">
      <div className={`related-highlight ${activeRelated}`} />
      <div className="related-tabs">
        <button
          type="button"
          className={`related-tab-button ${activeRelated === RelatedEnum.contracts ? 'active' : ''}`}
          onClick={() => handleSelectRelated(RelatedEnum.contracts)}
        >
          Договоры
        </button>
        <button
          type="button"
          className={`related-tab-button ${activeRelated === RelatedEnum.journal ? 'active' : ''}`}
          onClick={() => handleSelectRelated(RelatedEnum.journal)}
        >
          Журналы
        </button>
      </div>
    </div>
  );
};
