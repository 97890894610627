import { Table, Button, Typography } from "antd";
import { EditOutlined } from "@ant-design/icons";
import "./project-settings-individual.scss";

const settingsData = [
  {
    key: "1",
    object: "Корпус 1",
    deadlines: "Подключено",
    eojr: "Отключено",
  },
  {
    key: "2",
    object: 'Котельная ЖК "Ромашки"',
    deadlines: "Подключено",
    eojr: "Отключено",
  },
];


export const ProjectSettingsIndividual = () => {
  return (
    <div className="project-settings-individual">
      <Typography.Title level={4} className="project-settings-title">
        Индивидуальные настройки в объектах
      </Typography.Title>
      <div className="settings-content">
        <Table
          columns={[
            {
              title: "Объект",
              dataIndex: "object",
              key: "object",
            },
            {
              title: "Дедлайны",
              dataIndex: "deadlines",
              key: "deadlines",
            },
            {
              title: "Электронный ОЖР",
              dataIndex: "eojr",
              key: "eojr",
            },
          ]}
          dataSource={settingsData}
          pagination={false}
          bordered={false}
          className="settings-table"
        />
        <Button
          className="edit-button"
          shape="circle"
          icon={<EditOutlined />}
        />
      </div>
    </div>
  );
};
