import React from 'react';
import { Input } from 'antd';
import { Controller, Control, RegisterOptions } from 'react-hook-form';

interface IObjectFormInput {
  name?: string;
  control?: Control<any>;
  label?: string;
  placeholder?: string;
  width?: string | number;
  rules?: RegisterOptions;
  errorMessage?: string;
}

export const ObjectFormInput: React.FC<IObjectFormInput> = ({
  name,
  control,
  label,
  placeholder = '',
  width = '100%',
  rules,
  errorMessage,
}) => (
  <div className="form-section-label" style={{ width }}>
    <div className="form-label-input">{label}</div>
    <Controller
      name={name || 'test'}
      control={control}
      rules={rules}
      render={({ field }) => <Input {...field} placeholder={placeholder} className="object-form-input" />}
    />
    {errorMessage ? <div className="object-form-error">{errorMessage}</div> : null}
  </div>
);
