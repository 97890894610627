import { Outlet } from 'react-router-dom';
import './project-objects-view.scss';

export const ProjectObjectsView = () => {
  return (
    <div>
      <Outlet />
    </div>
  );
};
