import { createElement } from 'react';
import { RouteObject } from 'react-router-dom';
import ProjectWrapper from './ui/project-wrapper';

export const createProjectWrapper = (...rest: RouteObject[]): RouteObject => {
  return {
    element: createElement(ProjectWrapper),
    children: [...rest],
  };
};
