import { Button } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { ObjectFormSelector } from '../object-form-selector/object-form-selector';

export const ObjectFormMail = () => {
  return (
    <form>
      <div className="modal-form-section">
        <ObjectFormSelector label="Страна" placeholder="Россия" width={100} disabled />
      </div>

      <div className="modal-form-section">
        <ObjectFormSelector label="Субъект РФ" placeholder="Выберите" width={100} />
      </div>

      <div className="modal-form-section">
        <ObjectFormSelector
          label="Муниципальный район, муниципальный округ, городской округ или внутригородская территория (для объектов /федерального значения) в составе субъекта Российской Федерации, федеральная территория"
          placeholder=""
          width={100}
        />
      </div>

      <div className="modal-form-section">
        <ObjectFormSelector label="Код ОКТМО" placeholder="" width={100} />
      </div>

      <div>
        <div className="form-label-input primary" style={{ marginTop: '16px' }}>
          Адрес
        </div>
        <TextArea />
      </div>

      <div className="dottedLine" />

      <div className="object-modal-address-button">
        <Button type="primary">Добавить</Button>
      </div>
    </form>
  );
};
