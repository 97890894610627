export const routes = {
  root: '/',
  home() {
    return routes.root;
  },
  error() {
    return routes.root.concat('error-page/');
  },
  page_not_found() {
    return routes.root.concat('page-not-found/');
  },
  projects() {
    return routes.root.concat('projects/');
  },
  project: {
    root({ projectId = ':projectId' }) {
      return routes.root.concat(`${projectId}/`);
    },
    objects(projectId = ':projectId') {
      return routes.project.root({ projectId }).concat('objects');
    },
    requisites(projectId = ':projectId') {
      return routes.project.root({ projectId }).concat('requisites');
    },
    statistics(projectId = ':projectId') {
      return routes.project.root({ projectId }).concat('statistics');
    },
    docstore(projectId = ':projectId') {
      return routes.project.root({ projectId }).concat('docstore');
    },
    actRegistry(projectId = ':projectId') {
      return routes.project.root({ projectId }).concat('registry-of-acts');
    },
    journals(projectId = ':projectId') {
      return routes.project.root({ projectId }).concat('journals');
    },
  },
  objects: {
    root(projectId = ':projectId') {
      return routes.project.root({ projectId }).concat('objects/');
    },
    object(projectId = ':projectId', objectId = ':objectId') {
      return routes.objects.root(projectId).concat(`${objectId}/`);
    },
    users(projectId = ':projectId') {
      return routes.objects.object(projectId).concat('users/');
    },
    journals(projectId = ':projectId') {
      return routes.objects.object(projectId).concat('journals/');
    },
    settings(projectId = ':projectId') {
      return routes.objects.object(projectId).concat('settings/');
    },
  },
  general: {
    root(projectId = ':projectId') {
      return routes.project.root({ projectId }).concat('general/');
    },
    members(projectId = ':projectId') {
      return routes.general.root(projectId).concat('members/');
    },
    objects(projectId = ':projectId') {
      return routes.general.root(projectId).concat('objects/');
    },
    related(projectId = ':projectId') {
      return routes.general.root(projectId).concat('related/');
    },
    settings(projectId = ':projectId') {
      return routes.general.root(projectId).concat('settings/');
    },
    history(projectId = ':projectId') {
      return routes.general.root(projectId).concat('history/');
    },
  },
  organization() {
    return routes.root.concat('organization/');
  },
  account() {
    return routes.root.concat('account/');
  },
};
