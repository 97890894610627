import { Link } from 'react-router-dom';
import { routes } from '@shared/lib/react-router';

const NotFoundPage = () => {
  return (
    <div>
      Страница не найдена
      <Link to={routes.projects()}>На главную</Link>
    </div>
  );
};

export default NotFoundPage;
