import { Input, DatePicker } from 'antd';
import { Control, RegisterOptions, Controller } from 'react-hook-form';

interface IObjectFormInputDate {
  nameFirst: string;
  nameSecond: string;
  control: Control<any>;
  labelFirst?: string;
  labelSecond?: string;
  placeholderFirst?: string;
  placeholderSecond?: string;
  width?: string | number;
  rules?: RegisterOptions;
  errorMessage?: string;
}

export const ObjectFormInputDate = ({
  nameFirst,
  nameSecond,
  control,
  placeholderFirst,
  placeholderSecond,
  rules,
  errorMessage,
}: IObjectFormInputDate) => {
  return (
    <div className="field-group">
      <Controller
        name={nameFirst}
        control={control}
        render={({ field }) => <Input {...field} placeholder={placeholderFirst} className="object-form-input" />}
        rules={rules}
      />
      <Controller
        name={nameSecond}
        control={control}
        render={({ field }) => <DatePicker {...field} placeholder={placeholderSecond} className="object-form-input" />}
        rules={rules}
      />
      {errorMessage ? <div className="object-form-error">{errorMessage}</div> : null}
    </div>
  );
};
