import { Outlet } from 'react-router-dom';
import Navigation from './navigation';
import { Layout } from 'antd';

const ProjectWrapper = () => {
  return (
    <Layout>
      <Navigation />
      <Outlet />
    </Layout>
  );
};

export default ProjectWrapper;
