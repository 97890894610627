import { createElement } from 'react';
import { RouteObject } from 'react-router-dom';
import { routes } from '@shared/lib/react-router';
import { ProjectObject } from './ui';

export const ProjectObjectRoute = (...rest: RouteObject[]): RouteObject => {
  return {
    path: `${routes.general.objects()}`,
    element: createElement(ProjectObject),
    children: [...rest],
  };
};
