import { Card } from 'antd';
import Meta from 'antd/es/card/Meta';
import { useNavigate } from 'react-router-dom';
import { routes } from '@/shared/lib/react-router';
import example_image from '@shared/assets/png/exemple-project-1.png';
import ActionIcon from '@shared/assets/svg/icons/action-icon.svg?react';
import DocumIcon from '@shared/assets/svg/icons/docum-icon.svg?react';
import OrjIcon from '@shared/assets/svg/icons/ojr-icon.svg?react';

import './project-list.scss';

const projects = [
  {
    title: 'Загородный дом',
    image: example_image,
    description: 'Загородный дом Дмитрия в Горной Шальдихе',
    details: {
      tasks: 9,
      files: 24,
      folders: 4,
    },
    location: 'Ленинградская область, Кировский район, дер. Горная Шальдиха, ул. Хвойная, 40',
    contractors: ['ООО "РСТИ НА ГЛУХАРСКОЙ (СПЕЦИАЛИЗИРОВАННЫЙ ЗАСТРОЙЩИК)"', 'ООО "ПОДРЯДЧИК"'],
    id: 1,
  },
  {
    title: 'Корпус 2',
    image: '',
    description: 'Загородный дом Дмитрия в Горной Шальдихе',
    details: {
      tasks: 9,
      files: 24,
      folders: 4,
    },
    location: 'Ленинградская область, Кировский район, дер. Горная Шальдиха, ул. Хвойная, 40',
    contractors: ['ООО "РСТИ НА ГЛУХАРСКОЙ (СПЕЦИАЛИЗИРОВАННЫЙ ЗАСТРОЙЩИК)"', 'ООО "ПОДРЯДЧИК"'],
    id: 2,
  },
];

export const ProjectList = () => {
  const navigate = useNavigate();
  const handleNavigate = (link: number) => {
    navigate(`${routes.general.root(String(link))}`);
  };
  return (
    <main className="project-grid">
      {projects.map((project) => (
        <Card
          key={project.id}
          cover={
            project.image ? (
              <img src={project.image as string} alt={project.title} />
            ) : (
              <div className="image-placeholder" />
            )
          }
          className="project-card"
          onClick={() => handleNavigate(project.id)}
        >
          <Meta
            title={project.title}
            description={project.description}
            style={{ marginTop: '16px', marginBottom: '16px' }}
          />
          <div className="project-details">
            <div className="detail-item">
              <ActionIcon width={24} height={24} /> <div className="detail-item-count">{project.details.tasks}</div>
            </div>
            <div className="detail-item">
              <DocumIcon width={24} height={24} /> <div className="detail-item-count">{project.details.files}</div>
            </div>
            <div className="detail-item">
              <OrjIcon width={24} height={24} /> <div className="detail-item-count">{project.details.folders}</div>
            </div>
          </div>
          <div className="project-location">{project.location}</div>
          {project.contractors.map((contractor) => (
            <div className="project-contractors">
              <div key={contractor}>{contractor}</div>
            </div>
          ))}
        </Card>
      ))}
    </main>
  );
};
