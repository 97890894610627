import { createElement } from 'react';
import { RouteObject } from 'react-router-dom';
import { AdminLayout } from './ui/admin-layout';

export const createAdminLayout = (...rest: RouteObject[]): RouteObject => {
  return {
    element: createElement(AdminLayout),
    children: [...rest],
  };
};
