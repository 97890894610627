import { createElement } from 'react';
import { RouteObject } from 'react-router-dom';
import { UserLayout } from '@pages/layouts';
export { UserLayout } from './ui/user-layout';
export const createUserLayout = (...rest: RouteObject[]): RouteObject => {
  return {
    element: createElement(UserLayout),
    children: [...rest],
  };
};
