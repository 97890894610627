import { createElement } from 'react';
import { RouteObject } from 'react-router-dom';
import { routes } from '@shared/lib/react-router';
import { ProjectRelated } from './ui';

export const ProjectRelatedRoute = (...rest: RouteObject[]): RouteObject => {
  return {
    path: `${routes.general.related()}`,
    element: createElement(ProjectRelated),
    children: [...rest],
  };
};
